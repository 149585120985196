<template>
	<div>
		<el-form :inline="true" class="demo-form-inline">
			<el-form-item label="筛选:开始时间">
				<el-date-picker style="width:100%;" v-model="startTime" type="datetime"
					placeholder="开始日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
			<el-form-item label="筛选:结束时间">
				<el-date-picker style="width:100%;" v-model="endTime" type="datetime" placeholder="结束日期"
					value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</el-form-item>
		</el-form>
		<el-form :inline="true" class="demo-form-inline">
			<el-form-item>
				<el-button type="success" size="medium" icon="el-icon-search" @click="search">搜索</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" size="medium" icon="el-icon-refresh-left" @click="refresh">刷新</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="danger" size="medium" icon="el-icon-s-data" @click="handleDownload">导出Excel</el-button>
			</el-form-item>
		</el-form>
		<el-card class="box-card" style="margin-top:10px;">
			<div slot="header" style="text-align: left;">
				<span style="font-weight: bold;">入款（{{ruTable.length}}笔）</span>
			</div>
			<div>
				<el-table :data="ruTable" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
					:cell-style="{ textAlign: 'center' }">
					<el-table-column prop="time" label="时间"></el-table-column>
					<el-table-column show-overflow-tooltip prop="originalPrice" label="金额"></el-table-column>
					<el-table-column show-overflow-tooltip prop="finalPrice" label="结算"></el-table-column>
					<el-table-column show-overflow-tooltip prop="reUserName" label="回复人"></el-table-column>
					<el-table-column show-overflow-tooltip prop="userName" label="操作人"></el-table-column>
					<el-table-column show-overflow-tooltip prop="remark" label="备注"></el-table-column>
				</el-table>
			</div>
		</el-card>
		<el-card class="box-card" style="margin-top:20px;">
			<div slot="header" style="text-align: left;">
				<span style="font-weight: bold;">下发（{{xfTable.length}}笔）</span>
			</div>
			<div>
				<el-table :data="xfTable" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
					:cell-style="{ textAlign: 'center' }">
					<el-table-column prop="time" label="时间"></el-table-column>
					<el-table-column show-overflow-tooltip prop="finalPrice2" label="金额"></el-table-column>
					<el-table-column show-overflow-tooltip prop="originalPrice" label="换算"></el-table-column>
					<el-table-column show-overflow-tooltip prop="reUserName" label="回复人"></el-table-column>
					<el-table-column show-overflow-tooltip prop="userName" label="操作人"></el-table-column>
					<el-table-column show-overflow-tooltip prop="remark" label="备注"></el-table-column>
				</el-table>
			</div>
		</el-card>
		<el-card class="box-card" style="margin-top:20px; padding: 0 20px;">
			<el-collapse>
				<el-collapse-item title="按操作人分类" name="1">
					<el-table :data="leiTable1" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
						:cell-style="{ textAlign: 'center' }">
						<el-table-column show-overflow-tooltip prop="userName" label="操作人"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSumR" label="总入"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSum" label="总入款"></el-table-column>
						<el-table-column show-overflow-tooltip prop="xfSum" label="总下发"></el-table-column>
						<el-table-column show-overflow-tooltip prop="sy" label="剩余"></el-table-column>
					</el-table>
				</el-collapse-item>
				<el-collapse-item title="按回复人分类" name="2">
					<el-table :data="leiTable2" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
						:cell-style="{ textAlign: 'center' }">
						<el-table-column show-overflow-tooltip prop="reUserName" label="回复人"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSumR" label="总入"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSum" label="总入款"></el-table-column>
						<el-table-column show-overflow-tooltip prop="xfSum" label="总下发"></el-table-column>
						<el-table-column show-overflow-tooltip prop="sy" label="剩余"></el-table-column>
					</el-table>
				</el-collapse-item>
				<el-collapse-item title="按备注分类" name="3">
					<el-table :data="leiTable3" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
						:cell-style="{ textAlign: 'center' }">
						<el-table-column show-overflow-tooltip prop="remark" label="备注"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSumR" label="总入"></el-table-column>
						<el-table-column show-overflow-tooltip prop="ruSum" label="总入款"></el-table-column>
						<el-table-column show-overflow-tooltip prop="xfSum" label="总下发"></el-table-column>
						<el-table-column show-overflow-tooltip prop="sy" label="剩余"></el-table-column>
					</el-table>
				</el-collapse-item>
				<el-collapse-item title="按入款汇率分类" name="4">
					<el-table :data="leiTable4" v-loading="loading" border :header-cell-style="{ textAlign: 'center' }"
						:cell-style="{ textAlign: 'center' }">
						<el-table-column show-overflow-tooltip prop="exchangeRate" label="汇率"></el-table-column>
						<el-table-column show-overflow-tooltip prop="originalPrice" label="总入款"></el-table-column>
						<el-table-column show-overflow-tooltip prop="finalPrice" label="结算"></el-table-column>
					</el-table>
				</el-collapse-item>
			</el-collapse>
		</el-card>
		<el-card class="box-card" style="margin-top: 20px;">
			<div slot="header" style=" font-weight: bold;">
				<span>总计：</span>
			</div>
			<div style="padding: 10px 20px;" v-loading="loading">
				<div>总入款：{{ruSum}}</div>
				<div style="margin-top:8px;">应下发：{{yxf}}</div>
				<div style="margin-top:8px;">已下发：{{jxf}}</div>
				<div style="margin-top:8px;">待下发：{{dxf}}</div>
			</div>
		</el-card>
		<div style="padding:30px 0; text-align: center;"><a href="https://t.me/robotCg">专业开发TG机器人 - 飞机联系：@robotCg</a>
		</div>
	</div>
</template>


<script>
	import * as XLSX from 'xlsx'
	import axios from 'axios';
	export default {
		data() {
			return {
				url: 'https://qsapi.tgjz1.com',
				startTime: '',
				endTime: '',
				ruTable: [],
				xfTable: [],
				leiTable1: [],
				leiTable2: [],
				leiTable3: [],
				leiTable4: [],
				loading: true,

				ruSum: 0,
				yxf: 0,
				jxf: 0,
				dxf: 0,
			}
		},
		created() {
			this.startTime = this.getTodayRq()
			this.endTime = this.getTomorrowRq()
			this.getData();
		},
		methods: {
			async getData() {
				this.loading = true
				var startTime = this.startTime == null ? '' : this.startTime
				var endTime = this.endTime == null ? '' : this.endTime
				let zdData = await axios.get(
					`${this.url}/api/getDepositInfo?startTime=${startTime}&endTime=${endTime}&id=${this.getUrlParam('id')}`
				)

				//所有账单。数据处理
				zdData.data.data.forEach(item => {
					item.time = item.time.substring(5, item.time.length - 3)
				})

				//筛选入账下发账单
				let ruData = zdData.data.data.filter((item) => {
					return item.isDeposit == 0
				})
				let xfData = zdData.data.data.filter((item) => {
					return item.isDeposit == 1
				})

				let ruSum1 = 0
				var yxf1 = 0
				var jxf1 = 0
				ruData.forEach(item => {
					ruSum1 += this.twos(item.originalPrice)
					yxf1 += this.twos(item.finalPrice)
				})
				xfData.forEach(item => {
					jxf1 += this.twos(item.finalPrice2)
				})
				this.ruSum = this.twos(ruSum1)
				this.yxf = this.twos(yxf1)
				this.jxf = this.twos(jxf1)
				this.dxf = this.twos(yxf1 - jxf1)

				//回复人分类
				this.leiTable1 = this.mergeArrUserName(zdData.data.data)
				this.leiTable2 = this.mergeArrReUserName(zdData.data.data)
				this.leiTable3 = this.mergeArrRemark(zdData.data.data)
				this.leiTable4 = this.mergeArrExchangeRate(zdData.data.data)

				this.ruTable = ruData
				this.xfTable = xfData
				this.loading = false
			},
			async search() {
				this.getData();
			},
			async refresh() {
				this.getData();
			},
			mergeArrUserName(todos) {
				var that = this
				var nameArr = [...new Set(todos.map(i => i.userName))]; //名称集合
				let list = [];
				nameArr.forEach(v => {
					let filterlist = todos.filter(a => v == a.userName);
					list.push(filterlist)
				});
				let outArr = [];
				list.forEach((item, index) => {
					let userName = item.reduce((total, g) => {
						return g.userName
					}, 0);
					let ruSumR = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.originalPrice)
						} else {
							return total
						}
					}, 0);
					let ruSum = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.finalPrice)
						} else {
							return total
						}
					}, 0);
					let xfSum = item.reduce((total, g) => {
						if (g.isDeposit == 1) {
							return total + Number(g.finalPrice2)
						} else {
							return total
						}
					}, 0);
					outArr.push({
						userId: nameArr[index],
						userName: userName,
						ruSumR: that.twos(ruSumR),
						ruSum: that.twos(ruSum),
						xfSum: that.twos(xfSum),
						sy: that.twos(ruSum - xfSum),
					})
				})
				return outArr
			},
			mergeArrRemark(todos){
				var that = this
				var nameArr = [...new Set(todos.map(i => i.remark))]; //名称集合
				let list = [];
				nameArr.forEach(v => {
					let filterlist = todos.filter(a => v == a.remark);
					list.push(filterlist)
				});
				let outArr = [];
				list.forEach((item) => {
					let remark = item.reduce((total, g) => {
						return g.remark
					}, 0);
					let ruSumR = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.originalPrice)
						} else {
							return total
						}
					}, 0);
					let ruSum = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.finalPrice)
						} else {
							return total
						}
					}, 0);
					let xfSum = item.reduce((total, g) => {
						if (g.isDeposit == 1) {
							return total + Number(g.finalPrice2)
						} else {
							return total
						}
					}, 0);
					outArr.push({
						remark: remark,
						ruSumR: that.twos(ruSumR),
						ruSum: that.twos(ruSum),
						xfSum: that.twos(xfSum),
						sy: that.twos(ruSum - xfSum),
					})
				})
				return outArr
			},
			mergeArrReUserName(todos) {
				var that = this
				var nameArr = [...new Set(todos.map(i => i.reUserName))]; //名称集合
				let list = [];
				nameArr.forEach(v => {
					let filterlist = todos.filter(a => v == a.reUserName);
					list.push(filterlist)
				});
				let outArr = [];
				list.forEach((item, index) => {
					let reUserName = item.reduce((total, g) => {
						return g.reUserName
					}, 0);
					let ruSumR = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.originalPrice)
						} else {
							return total
						}
					}, 0);
					let ruSum = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.finalPrice)
						} else {
							return total
						}
					}, 0);
					let xfSum = item.reduce((total, g) => {
						if (g.isDeposit == 1) {
							return total + Number(g.finalPrice2)
						} else {
							return total
						}
					}, 0);
					outArr.push({
						userId: nameArr[index],
						reUserName: reUserName,
						ruSumR: that.twos(ruSumR),
						ruSum: that.twos(ruSum),
						xfSum: that.twos(xfSum),
						sy: that.twos(ruSum - xfSum),
					})
				})
				return outArr
			},
			mergeArrExchangeRate(todos) {
				var that = this
				var nameArr = [...new Set(todos.map(i => i.exchangeRate))]; //名称集合
				let list = [];
				nameArr.forEach(v => {
					let filterlist = todos.filter(a => v == a.exchangeRate);
					list.push(filterlist)
				});
				let outArr = [];
				list.forEach((item, index) => {
					let exchangeRate = item.reduce((total, g) => {
						return g.exchangeRate
					}, 0);
					let originalPrice = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.originalPrice)
						} else {
							return total
						}
					}, 0);
					let finalPrice = item.reduce((total, g) => {
						if (g.isDeposit == 0) {
							return total + that.twos(g.finalPrice)
						} else {
							return total
						}
					}, 0);
					outArr.push({
						userId: nameArr[index],
						exchangeRate: exchangeRate,
						originalPrice: that.twos(originalPrice),
						finalPrice: that.twos(finalPrice),
					})
				})
				return outArr
			},
			twos(sum) {
				return Math.round(sum * 100) / 100
			},
			getUrlParam(param) {
				// 获取由全部参数组成的字符串。
				let query = window.location.search.substring(1);
				// 分割参数，得到每一个参数字符串组成的数组。
				let vars = query.split('&');
				// 遍历数组，得到每一个参数字符串。
				for (let i = 0; i < vars.length; i++) {
					// 分割每一个参数字符串，得到参数名和参数值组成的数组。
					var pair = vars[i].split('=');
					// 如果参数名等于传入的param，则返回该值。
					if (pair[0] == param) {
						return decodeURI(pair[1]);
					}
				}
				// 若参数不存在，则返回false。
				return false;
			},
			getTodayRq() {
				var date = new Date();
				// 获取当前月份
				var nowMonth = date.getMonth() + 1;
				// 获取当前是几号
				var strDate = date.getDate();
				// 添加分隔符“-”
				var seperator = "-";
				// 对月份进行处理，1-9月在前面添加一个“0”
				if (nowMonth >= 1 && nowMonth <= 9) {
					nowMonth = "0" + nowMonth;
				}
				// 对月份进行处理，1-9号在前面添加一个“0”
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				return date.getFullYear() + seperator + nowMonth + seperator + strDate + ' 00:00:00'
			},
			getTomorrowRq() {
				var date = new Date();
				date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
				// 获取当前月份
				var nowMonth = date.getMonth() + 1;
				// 获取当前是几号
				var strDate = date.getDate();
				// 添加分隔符“-”
				var seperator = "-";
				// 对月份进行处理，1-9月在前面添加一个“0”
				if (nowMonth >= 1 && nowMonth <= 9) {
					nowMonth = "0" + nowMonth;
				}
				// 对月份进行处理，1-9号在前面添加一个“0”
				if (strDate >= 0 && strDate <= 9) {
					strDate = "0" + strDate;
				}
				return date.getFullYear() + seperator + nowMonth + seperator + strDate + ' 00:00:00'
			},
			handleDownload() {
				var ruList = []
				this.ruTable.map(i => {
					ruList.push(Object.assign({}, {
						"时间": i.time,
						"金额": i.originalPrice,
						"结算": i.finalPrice,
						"回复人": i.reUserName,
						"操作人": i.userName,
						"备注": i.remark
					}))
				})

				var xfList = []
				this.xfTable.map(i => {
					xfList.push(Object.assign({}, {
						"时间": i.time,
						"金额": i.finalPrice2,
						"结算": i.originalPrice,
						"回复人": i.reUserName,
						"操作人": i.userName,
						"备注": i.remark
					}))
				})

				var zjList = [
					["总计"],
					["总入款", this.ruSum],
					["应下发", this.yxf],
					["已下发", this.jxf],
					["待下发", this.dxf]
				]
				var ws = XLSX.utils.aoa_to_sheet([
					[]
				]);
				XLSX.utils.sheet_add_aoa(ws, [
					[`入款（${ruList.length}笔）`]
				], {
					origin: "A1",
					dense: true
				});
				XLSX.utils.sheet_add_json(ws, ruList, {
					origin: "A2"
				});
				XLSX.utils.sheet_add_aoa(ws, [
					[`下发（${ruList.length}笔）`]
				], {
					origin: -1
				});
				XLSX.utils.sheet_add_json(ws, xfList, {
					origin: -1
				});
				XLSX.utils.sheet_add_json(ws, zjList, {
					origin: -1,
					skipHeader: true
				});
				if (!ws['A1'].s) ws['A1'].s = {};
				ws['A1'].s.alignment = { horizontal: 'center', vertical: 'center' };
				ws['A1'].z = '0';
				
				var COL_INDEX = [0, 1, 2, 3, 4, 5];
				if (!ws["!cols"]) ws["!cols"] = [];
				COL_INDEX.forEach(item => {
					if (!ws["!cols"][item]) ws["!cols"][item] = {
						wch: 8
					};
					ws["!cols"][item].wpx = 100;
				})
				if (!ws["!rows"]) ws["!rows"] = [];
				for(var z=0;z<500;z++){
					if (!ws["!rows"][z]) ws["!rows"][z] = {
						hpx: 40
					};
					ws["!rows"][z].hpx = 28;
				}
				var wb = XLSX.utils.book_new();
				console.log(ws)
				XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
				XLSX.writeFile(wb, "账单"+this.dateFormat(new Date())+".xlsx", {cellStyles: true});

			},
			dateFormat(d) {
				var date = new Date(d);
				var YY = date.getFullYear();
				var MM = date.getMonth() + 1
				var DD = date.getDate();
				var hh = this.addZero(date.getHours());
				var mm = this.addZero(date.getMinutes());
				var ss = this.addZero(date.getSeconds());
				if(MM<10){
					MM = '0' + MM
				}
				if(DD<10){
					DD = '0' + DD
				}
				return YY + "-" + MM + "-" + DD + " " + hh + ":" + mm + ":" + ss
			},
			addZero(n) {
				return n < 10 ? "0" + n : n + "";
			}
		}
	}
</script>

<style>
	.el-card__header {
		padding: 10px 20px;
	}

	.el-card__body {
		padding: 0;
	}

	.el-table {
		width: 100%;
	}

	el-collapse-item {
		margin: 0 20px;
	}
</style>